// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

	--ion-color-primary: #14db7e;
	--ion-color-primary-rgb: 20,219,126;
	--ion-color-primary-contrast: #000000;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #12c16f;
	--ion-color-primary-tint: #2cdf8b;

	--ion-color-secondary: #47906e;
	--ion-color-secondary-rgb: 71,144,110;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #3e7f61;
	--ion-color-secondary-tint: #599b7d;

	--ion-color-tertiary: #9752ff;
	--ion-color-tertiary-rgb: 151,82,255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #8548e0;
	--ion-color-tertiary-tint: #a163ff;
  
	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45,211,111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;

	--ion-color-danger: #eb445a;
	--ion-color-danger-rgb: 235,68,90;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #cf3c4f;
	--ion-color-danger-tint: #ed576b;

  --ion-color-light: #f3f3ec;
	--ion-color-light-rgb: 243,243,236;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #d6d6d0;
	--ion-color-light-tint: #f4f4ee;

	--ion-color-medium: #a3a199;
	--ion-color-medium-rgb: 163,161,153;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #8f8e87;
	--ion-color-medium-tint: #acaaa3;

  --ion-color-lighter: #f7f7f7;
  --ion-color-lighter-rgb: 251, 249, 234;
  --ion-color-lighter-contrast: #000000;
  --ion-color-lighter-contrast-rgb: 0,0,0;
  --ion-color-lighter-shade: #e0d9c0;
  --ion-color-lighter-tint: #fbf9ea;

  --default-background: #ffffff;
  --default-background-rgb: 255, 255, 255;
  --default-form-background: #ffffff;

  --accent-background: #fbf9ea;
  --accent-background-rgb: 251, 249, 234;

  --ion-text-color: #000;
  --ion-text-color-rgb: 0,0,0;

  --http-get: 97, 175, 254;
  --http-post: 0, 125, 255;
  --http-put: 252, 161, 48;
  --http-delete: 249, 62, 62;
  --http-patch: 252, 161, 48;

  --font-color: var(--ion-text-color);
  --ion-color-keep-dark: var(--ion-text-color);

  .color-http-get {
    --color: rgba(var(--http-get));
    color: var(--color);
  }
  .color-http-post {
    --color: rgba(var(--http-post));
    color: var(--color);
  }
  .color-http-put {
    --color: rgba(var(--http-put));
    color: var(--color);
  }
  .color-http-delete {
    --color: rgba(var(--http-delete));
    color: var(--color);
  }
}


  /*
   * Dark Colors
   * -------------------------------------------
   */

  body.dark {

    --ion-color-primary: #14db7e;
    --ion-color-primary-rgb: 20,219,126;
    --ion-color-primary-contrast: #000000;
    --ion-color-primary-contrast-rgb: 0,0,0;
    --ion-color-primary-shade: #12c16f;
    --ion-color-primary-tint: #2cdf8b;
  
    --ion-color-secondary: #47906e;
    --ion-color-secondary-rgb: 71,144,110;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255,255,255;
    --ion-color-secondary-shade: #3e7f61;
    --ion-color-secondary-tint: #599b7d;
  
    --ion-color-tertiary: #9752ff;
    --ion-color-tertiary-rgb: 151,82,255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255,255,255;
    --ion-color-tertiary-shade: #8548e0;
    --ion-color-tertiary-tint: #a163ff;
    
    --ion-color-success: #2fdf75;
    --ion-color-success-rgb: 47,223,117;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0,0,0;
    --ion-color-success-shade: #29c467;
    --ion-color-success-tint: #44e283;

    --ion-color-warning: #ffd534;
    --ion-color-warning-rgb: 255,213,52;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0,0,0;
    --ion-color-warning-shade: #e0bb2e;
    --ion-color-warning-tint: #ffd948;

    --ion-color-danger: #ff4961;
    --ion-color-danger-rgb: 255,73,97;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255,255,255;
    --ion-color-danger-shade: #e04055;
    --ion-color-danger-tint: #ff5b71;

    --ion-color-dark: #f8f6f4;
    --ion-color-dark-rgb: 248,246,244;
    --ion-color-dark-contrast: #000000;
    --ion-color-dark-contrast-rgb: 0,0,0;
    --ion-color-dark-shade: #d7d8da;
    --ion-color-dark-tint: #f5f6f9;

    --ion-color-medium: #a2a198;
    --ion-color-medium-rgb: 162,161,152;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0,0,0;
    --ion-color-medium-shade: #8f8d86;
    --ion-color-medium-tint: #aba9a2;

    --ion-color-light: #2a2923;
    --ion-color-light-rgb: 42, 41, 35;
    --ion-color-light-contrast: #ffffff;
    --ion-color-light-contrast-rgb: 255,255,255;
    --ion-color-light-shade: #23231e;
    --ion-color-light-tint: #3e3d38;

    --ion-color-lighter: #282722;
    --ion-color-lighter-rgb: 34,36,40;
    --ion-color-lighter-contrast: #ffffff;
    --ion-color-lighter-contrast-rgb: 255,255,255;
    --ion-color-lighter-shade: #1e2023;
    --ion-color-lighter-tint: #383a3e;

    --accent-background: #10100f;
    --accent-background-rgb: 16, 16, 15;

      --ion-background-color: var(--ion-color-step-100);
      --default-background: #121212;
      --default-background-rgb: 18, 18, 18;
      --default-form-background: #131211;

      icon-toggle .toggle-grab {
        --grab-background-color: var(--ion-color-light-tint);
      }
      any-switch.default-switch, any-switch.snapshot-switch, any-switch-popover {
        --background: var(--default-form-background);
      }
      ion-title.title-large {
        --color: white;
      }


      --ion-background-color: #121212;
      --ion-background-color-rgb: 18,18,18;
  
      --ion-text-color: #ffffff;
      --ion-text-color-rgb: 255,255,255;
  
      --ion-border-color: #222222;
  
      --ion-color-step-50: #1e1e1e;
      --ion-color-step-100: #2a2a2a;
      --ion-color-step-150: #363636;
      --ion-color-step-200: #414141;
      --ion-color-step-250: #4d4d4d;
      --ion-color-step-300: #595959;
      --ion-color-step-350: #656565;
      --ion-color-step-400: #717171;
      --ion-color-step-450: #7d7d7d;
      --ion-color-step-500: #898989;
      --ion-color-step-550: #949494;
      --ion-color-step-600: #a0a0a0;
      --ion-color-step-650: #acacac;
      --ion-color-step-700: #b8b8b8;
      --ion-color-step-750: #c4c4c4;
      --ion-color-step-800: #d0d0d0;
      --ion-color-step-850: #dbdbdb;
      --ion-color-step-900: #e7e7e7;
      --ion-color-step-950: #f3f3f3;
  
      --ion-item-background: #131211;
  
      --ion-toolbar-background: #131211;
  
      --ion-tab-bar-background: #131211;
  
      --ion-card-background: #131211;

      body.dark ion-modal {
        --ion-background-color: var(--ion-color-step-200);
        --ion-toolbar-background: var(--ion-color-step-250);
        --ion-toolbar-border-color: var(--ion-color-step-350);
        --ion-item-background: var(--ion-color-step-250);
      }
  }